






import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

@Component
export default class MIcon extends Vue {
  @Prop() icon!: string

  get iconName(): string {
    return 'mdi-' + this.icon
  }
}
