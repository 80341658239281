




















import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'
import MIcon from '@/components/common/MIcon.vue'

@Component({
  components: { MIcon }
})
export default class MTextField extends Vue {
  @Prop() value!: string
  @Prop() icon!: string | null
  @Prop({ default: '' }) placeholder!: string
  @Prop() type!: string
  @Prop(Boolean) borderless!: boolean
  @Prop(Boolean) readonly!: boolean

  isFocused = false

  get newValue(): string {
    return this.value
  }

  set newValue(val: string) {
    this.$emit('input', val)
  }

  onInput(event: InputEvent): void {
    if (event.target) {
      this.newValue = (event.target as any).value
    }
  }

  focus(): void {
    this.$nextTick(() => {
      const input = this.$el.querySelector('input') as HTMLInputElement
      if (input) {
        input.focus()
      }
    })
  }

  onBlur(event: FocusEvent): void {
    this.$emit('blur', event)
    this.isFocused = false
  }

  onFocus(event: FocusEvent): void {
    this.$emit('focus', event)
    this.isFocused = true
  }
}
