



















import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'
import MIcon from '@/components/common/MIcon.vue'
import { ButtonType } from './types'
@Component({
  components: { MIcon }
})
export default class MButton extends Vue {
  @Prop() icon!: string
  @Prop(Boolean) iconRight!: boolean
  @Prop({ default: null }) routerLink!: string | null
  @Prop({ required: true }) type!: ButtonType
  @Prop(Boolean) isSmall!: boolean

  ButtonType = ButtonType

  onClick(event: MouseEvent): void {
    this.$emit('click', event)
    if (this.routerLink) {
      this.$router.push(this.routerLink)
    }
  }
}
