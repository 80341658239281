var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"m-button",class:{
    'is-primary': _vm.type === _vm.ButtonType.PRIMARY,
    'is-primary-outlined': _vm.type === _vm.ButtonType.PRIMARY_OUTLINED,
    'is-secondary': _vm.type === _vm.ButtonType.SECONDARY,
    'is-danger': _vm.type === _vm.ButtonType.DANGER,
    'is-hover-border': _vm.type === _vm.ButtonType.HOVER_BORDER,
    isSmall: _vm.isSmall
  },on:{"click":function($event){return _vm.onClick($event)}}},[(_vm.icon && !_vm.iconRight)?_c('m-icon',{staticClass:"icon-left",attrs:{"icon":_vm.icon}}):_vm._e(),(_vm.$slots.default)?_c('p',[_vm._t("default")],2):_vm._e(),(_vm.icon && _vm.iconRight)?_c('m-icon',{staticClass:"icon-right",attrs:{"icon":_vm.icon}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }